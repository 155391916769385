export default {
  $vuetify: {
    dataIterator: {
      rowsPerPageText: 'Items per page:',
      rowsPerPageAll: 'All',
      pageText: '{0}-{1} of {2}',
      noResultsText: 'No matching records found',
      nextPage: 'Next page',
      prevPage: 'Previous page'
    },
    dataTable: {
      rowsPerPageText: 'Rows per page:'
    },
    noDataText: 'No data available'
  },
  user: {
    email: 'Email',
    name: 'Name',
    role: 'Role',
    actions: 'Actions',
    loginError: 'Wrong email or password, please try again.',
    WRONG_OTP: 'Wrong OTP token. Try Again.',
    alreadySentOtp: 'OTP already sent to your mail. Check Again'
  },
  menu: {
    shop: 'Shop',
    home: 'Home',
    dashboard: 'Dispatch Dashboard',
    eld_dashboard: 'ELD Dashboard'
  },
  other: {
    users: 'Users',
    roles: 'Roles',
    search: 'Search',
    permit_books: 'Permit books',
    search_eld_truck: 'Search by truck number',
    searchDriversByName: 'by name',
    searchDriversByTruck: 'by truck',
    searchDriversByTrailer: 'by trailer',
    logout: 'Logout',
    save: 'Save',
    config: 'Config',
    boards: 'Boards',
    companies: 'Companies',
    leaseCompanies: 'Leasing Companies',
    leaseUnits: 'Leasing Units',
    drivers: 'Drivers',
    drivers_deleted: 'Inactive Drivers',
    loadTypes: 'Load Types',
    logs: 'Logs',
    contacts: 'Contacts',
    messages: 'Messages',
    messages_daily_report: 'Daily report for market messages',
    messages_weekly_report: 'Weekly report for market messages',
    inbox: 'Important notifications',
    shifts: 'Shifts',
    eld_drivers: 'ELD drivers',
    eld_trucks: 'ELD trucks',
    eld_companies: 'ELD companies',
    eld_types: 'Call types',
    byUserCalls: 'User',
    byTruckCalls: 'Truck',
    dot: 'DOT inspections',
    eldCompanies: 'Eld Companies',
    eldTrucks: 'Eld Trucks',
    eldDrivers: 'Eld Drivers',
    dotInspections: 'Dot Inspections',
    menageFm: 'Manage FM',
    fmBoard: 'FM board',
    fmBoardNIS: 'NIS',
    fmBoardNIS1: 'NIS 1',
    fmBoardNIS2: 'NIS 2',
    fmBoardBG: 'BG',
    fmBoardBG1: 'BG 1',
    fmBoardBG2: 'BG 2',
    fmBoardBG3: 'BG 3',
    trailers: 'Trailers',
    availableTrailers: 'Available Trailers',
    availableTrailersHistory: 'AT History',
    availableTrailersInactive: 'Inactive Drivers',
    trailerMarks: 'Trailer Makes',
    trailerTypes: 'Trailer Types',
    trailerStatistics: 'Trailer Statistics',
    thirdParty: 'Third party',
    owners: 'Owners',
    searchTrailers: 'By trailer number or vin',
    searchTrucks: 'By truck number or vin or driver name',
    dealerships: 'Dealerships',
    banks: 'Banks',
    yards: 'Yards',
    searchFMDrivers: 'Search drivers',
    truckMake: 'Truck Make',
    truckModel: 'Truck Model',
    trucks: 'Trucks',
    prebookTrucks: 'Post trucks',
    prebookTrucksPosted: 'Posted trucks',
    prebookLoads: 'Post loads',
    availableTrucks: 'Available Trucks',
    customize: 'Content',
    statististics: 'Statistics',
    units: 'Units',
    driver_documents: 'Driver documents',
    charts: {
      truckMakeStats: 'Truck Make',
      trailerMakeStats: 'Trailer Make',
      truckModelStats: 'Truck Models',
      divisionStats: 'Division',
      ownerStats: 'Owner',
      engineStats: 'Engine',
      gpsTypeStats: 'GPS type',
      trucksStatistics: 'Trucks Statistics',
      thirdParty: 'In Company',
      activeTrucks: 'Active trucks',
      trucksByYear: 'Trucks by Year',
      trailersByYear: 'Trailers by Year',
      trailersByType: 'Trailers by Type',
      activeTrailers: 'Active trailers'
    },
    recruitment: {
      tab: 'Recruitment'
    },
    accounting: {
      banks: 'Banks',
      contracts: 'Contracts',
      units: 'Units (financed)',
      allUnits: 'All Units',
      lease: 'Lease',
      paidWithCash: 'Units (paid with cash)',
      dealers: 'Dealers',
      unitsHistory: 'Units History'
    },
    cars: {
      cars: 'Cars',
      makes: 'Car makes',
      models: 'Car models',
    },
    manage_app_users: 'Manage app users',
    fnd_users: 'FND Users'
  },
  FIELD_REQUIRED: 'Field {field} is required',
  FIELD_NUMERIC: 'Filed {field} can contain numbers only',
  FIELD_IMAGE: 'Supported files are .jpg, .jpeg and .png',
  RESET_PASSWORD_EMAIL_SENT: 'You have been sent an email with a link to reset your password',
  USER_NOT_FOUND: 'User not found',
  TOKEN_EXPIRED: 'Token for password reset expired',
  SET_PASSWORD: 'Create a password',
  COPY_TO_CLIPBOARD_SUCCESS : 'Successfully copied',
  EMAIL_UNIQUE_ERROR: 'Email must be unique',
  ROLE_UNIQUE_ERROR: 'Role must be unique',
  CANT_BE_DELETED: 'This role belongs to a user and can\'t be deleted',
  contact_fields: {
    first_ext_field: 'floyd_ext',
    first_ext_label: 'Floyd Ext',
    second_ext_field: 'kordun_ext',
    second_ext_label: 'Trytime ext',
    third_ext_label: 'Rocket Ext',
    fourth_ext_label: 'Jordan Ext'
  },
  pre_book: {
    post_trucks: 'Post trucks',
  },
  payment_started: 'PAYMENT STARTED',
  dot_inspection_date: 'Dot inspection date',
  ALREADY_BOOKED: 'Sorry, this load is already booked!',
  ALREADY_CLOSED: 'Sorry, this load is already closed!',
  GENERIC_ERROR_MESSAGE: 'Something went wrong!',
  LOAD_IS_DEDICATED: 'This load is dedicated',
  PREBOOK_LOADS_DHO_INFO: 'Exact distance calculated via maps',
  PREBOOK_NEAR_LOADS_IN_RADIUS: 'Nearby loads located within a 50 mile from this one'
}
